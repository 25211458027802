.container {
  width: 100%;
  height: 100%;
}

.play-button {
  width: 72px;
  height: 72px;
  background-image: url('./assets/play-button.svg');
}
