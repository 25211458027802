.photo_gallery_container {
  position: relative;
  background-color: white;
}

@media print {
  .photo_gallery_container {
    display: none;
  }
}
