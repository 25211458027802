.container {
  position: relative;
  margin: 0;
  padding: 0;
  display: flex;
  overflow: scroll hidden;
  width: 100%;
  height: 100%;
  list-style: none;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.container::-webkit-scrollbar {
  display: none;
}
