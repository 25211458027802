.menu_button {
  padding: 7px 10px;
  display: inline-block;
  cursor: pointer;
}

.menu_button:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.title {
  margin-left: 10px;
  display: inline-block;
  font-family: Lato;
  font-size: 14px;
  font-weight: bold;
  vertical-align: top;
}

.icon {
  display: inline-block;
  width: 15px;
  height: 15px;
  vertical-align: sub;
  background-repeat: no-repeat;
  background-position: 50%;
}

.icon-photo {
  composes: icon;
  background-image: url('./assets/ic-photo.svg');
}

.icon-video {
  composes: icon;
  background-image: url('./assets/ic-video.svg');
}

.icon-layout,
.icon-cian-layout {
  composes: icon;
  background-image: url('./assets/ic-plan.svg');
}
