.container {
  width: 100%;
  height: 100%;
  min-height: 0;
}

.container:focus {
  outline: none;
}

.scroller {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 0;
  overflow: hidden;
}

.scroller--bordered {
  border: 1px solid var(--gray20_100);
  border-radius: 4px;
}

.thumbs-wrapper {
  width: 100%;
  margin-top: var(--unit_2);
}

.thumbs-wrapper--bounded {
  margin: var(--unit_2) auto;
  max-width: 718px;
}

@media (min-width: 1440px) {
  .thumbs-wrapper--bounded {
    max-width: 1036px;
  }
}
