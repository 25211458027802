.label {
  position: absolute;
  z-index: 10;
  top: 12px;
  left: 20px;
  padding: 4px 8px;
  display: inline-block;
  font-size: 12px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  text-align: center;
  letter-spacing: normal;
  cursor: pointer;
  color: white;
  border-radius: 2px;
  box-shadow: 0 2px 6px 0 rgba(2, 2, 2, 0.24);
}

.background-sale {
  background-color: #ea4f6b;
}

.background-bonus {
  background-color: #2e9e00;
}

.background-mortgage {
  background-color: #ff9d00;
}
