.container {
  width: 100%;
  height: 520px;
  margin-top: 24px;
  padding: 0 24px;
}

.no-media {
  width: 100%;
  height: 120px;
  background-color: #e4e4e4;
  background-image: url('./assets/no-media.svg');
  background-repeat: no-repeat;
  background-position: 50%;
}
