.controls {
  position: absolute;
  left: 34px;
  bottom: 81px;
  color: white;
  background-color: rgba(24, 41, 48, 0.8);
  border-radius: 2px;
}

.controls--hidden {
  display: none;
}

.single {
  bottom: 33px;
}
