.container {
  padding-bottom: 2px;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 102;
  padding: 16px;
  background: none;
  border: none;
  cursor: pointer;
}
