.container {
  position: absolute;
  top: 15px;
  left: 40px;
  display: flex;
}

.label + .label {
  margin-left: 5px;
}
