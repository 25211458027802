@import '@cian/ui-kit-design-tokens/colors/semanticPalette.css';

.container {
  position: absolute;
  top: 16px;
  right: 16px;
  background: var(--overlay-default);
  padding: 12px;
  border-radius: 40px;
  cursor: pointer;
  pointer-events: initial;
}
