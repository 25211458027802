.container {
  padding-bottom: 2px;
  display: flex;
  flex-direction: column;
  height: calc(100% - 60px);
}

.inner {
  height: 100%;
  display: flex;
  flex-direction: row;
}

.gallery {
  width: calc(100% - 360px);
}

.header {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  border-bottom: 1px solid #c9d1e5;
}

.close {
  z-index: 102;
  padding: 16px;
  background: none;
  border: none;
  cursor: pointer;
}
