@import '@cian/ui-kit/units/variables.module.css';

.container {
  --image-size: 154px;

  padding-right: calc(var(--image-size) + var(--unit_5));
  background-image: url('./assets/illustration.svg');
  background-repeat: no-repeat;
  background-position: top right;
  background-size: var(--image-size) auto;
}

.container--without-button {
  padding: 40px 0;
}

.title {
  margin-bottom: var(--unit_2);
}

.subtitle {
  margin-bottom: var(--unit_4);
}
