@import '@cian/ui-kit-design-tokens/colors/semanticPalette.css';

.button {
  position: relative;
  background-color: transparent;
  display: flex;
  align-items: center;
  padding: 0 0 7px;
  margin: 8px 20px;
  border: 0;
  cursor: pointer;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
}

.button::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  height: 3px;
  width: 100%;
  margin-top: 4px;
  background-color: white;
}

.button_active {
  font-weight: bold;
  color: var(--accent-main-primary);
}

.button_active::after {
  content: '';
  height: 3px;
  width: 100%;
  background-color: var(--accent-main-primary);
}
