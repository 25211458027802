.container {
  width: 64px;
  height: 64px;
  flex: 0 0 auto;
  overflow: hidden;
  border-radius: 4px;
  box-sizing: border-box;
}

.container ~ .container {
  margin-left: var(--unit_2);
}

.container--active {
  border: 2px solid var(--accent-main-primary);
}

.container > a {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  text-decoration: none;
  cursor: pointer;
}

.container > a::after {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  content: '';
  background: rgba(0 0 0 / 20%);
}

.container > a:hover::after,
.container--active > a::after {
  display: none;
}

.play-button {
  display: flex;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: rgba(4, 104, 255, 0.64);
  padding: var(--unit_3);
}
