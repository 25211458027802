/* stylelint-disable */

.controls {
  position: fixed;
  z-index: 101;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 70px;
  color: white;
  background-color: black;
  justify-content: flex-end;
  align-items: center;

  button.favorites {
    display: flex;
    color: white;
    background-color: rgba(255, 255, 255, 0.15);
    transition:
      color 0.3s ease,
      background-color 0.3s ease;

    &:hover {
      color: rgba(255, 255, 255, 0.5);
    }
  }
}

.controls--next-compat {
  position: relative;
  flex: 0 0 auto;
  padding-right: 64px;
}

.favorites-wrapper {
  margin-left: 20px;
}

.title {
  position: absolute;
  left: 20px;
  font-family: Lato;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.38;
  text-align: left;
  color: white;
  opacity: 0.92;
}

.close {
  margin-right: 20px;
  margin-left: 32px;
  width: 14px;
  height: 14px;
  cursor: pointer;
  background-image: url('../assets/ic-close-white.svg');
}

.phone {
  font-size: 16px;
  font-weight: bold;
  line-height: 1.38;
}

.show-phone {
  text-decoration: none;
  color: white;
  background-color: #2b87db;
  font-weight: 700;
  white-space: nowrap;
  padding: 3px 16px 5px;
  height: 28px;
  font-size: 14px;
  line-height: 20px;
  border-radius: 42px;
  border: none;
  transition:
    color 0.3s ease-out,
    background-color 0.3s ease-out,
    box-shadow 0.05s;
  outline: none;
  cursor: pointer;

  &:hover {
    background-color: #256ba5;
  }

  &:focus {
    box-shadow: 0 0 0 3px #2b87db7a;
  }

  &:active {
    transform: scale(0.98);
  }
}
