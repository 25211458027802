.wrapper {
  width: 360px;
  height: 100%;
  overflow: scroll;
  padding: 24px 40px;
  background-color: white;
  border-left: 1px solid #c9d1e5;
  flex-shrink: 0;
}

.title {
  margin-bottom: 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.actions {
  margin-left: 8px;
}

.buttons {
  margin-top: 24px;
  margin-bottom: 24px;
}
