.section {
  margin-bottom: 8px;
  background-color: white;
  border: 1px solid var(--gray6_100);
  border-radius: 4px;
  overflow: hidden;
}

.section:empty {
  display: none;
}

@media print {
  .section {
    display: none;
  }
}
