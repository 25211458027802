.container {
  position: absolute;
  left: 50%;
  bottom: 16px;
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
  background: var(--decorative-theme-dark);
  border-radius: 4px;
  overflow: hidden;
  transform: translateX(-50%);
  white-space: nowrap;
}
