.item {
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 14px;
  background: #f3f6ff;
  cursor: pointer;
}
