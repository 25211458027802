@import '@cian/ui-kit-design-tokens/colors/semanticPalette.css';

.button {
  display: flex;
  align-items: center;
  padding: 8px 16px;
  background: var(--decorative-theme-dark);
  border: 0;
  cursor: pointer;
}

.button:hover {
  background-color: var(--gray60_100);
}

.icon {
  margin-right: 4px;
}
