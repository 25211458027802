.phone {
  margin-top: 8px;
  display: block;
  text-decoration: none;
  font-weight: bold;
  color: #182930;
}

.phone:first-child {
  margin-top: 0;
}

.phone-size-xs {
  font-size: 16px;
  line-height: 1.75;
}
